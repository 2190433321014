(function () {
  ("use strict");

  // DINAMYC FULL HEIGHT AND WIDTH BACKGROUND IMAGE ON WINDOW
  function setDimensions() {
    var windowsHeight = $(window).height();
    $(".height-dinamyc").css("height", windowsHeight + "px");
  }
  // when resizing the site, we adjust the heights of the sections
  $(window).resize(function () {
    setDimensions();
  });
  setDimensions();

  // NAVBAR SCROLL
  $(document).ready(function () {
    var scrollTop = 0;
    $(window).scroll(function () {
      scrollTop = $(window).scrollTop();
      if (scrollTop >= 200) {
        $(".navbar").addClass("scrolled");
      } else if (scrollTop < 200) {
        $(".navbar").removeClass("scrolled");
      }
    });
  });

  // LIGHTBOX GALLERY
  $(document).ready(function() {
    $(".fancybox").jqPhotoSwipe({
      galleryOpen: function (gallery) {
        //with `gallery` object you can access all methods and properties described here http://photoswipe.com/documentation/api.html
        //console.log(gallery);
        //console.log(gallery.currItem);
        //console.log(gallery.getCurrentIndex());
        //gallery.toggleDesktopZoom();
      }
    });
    $(".forcedgallery a").jqPhotoSwipe({
      forceSingleGallery: true
    });
  });

  // DROPDOWN MENU
  $(document).ready(function () {
    $(".dropdown-menu a.dropdown-toggle").on("click", function (e) {
      var $el = $(this);
      var $parent = $(this).offsetParent(".dropdown-menu");
      if (!$(this).next().hasClass("show")) {
        $(this)
          .parents(".dropdown-menu")
          .first()
          .find(".show")
          .removeClass("show");
      }
      var $subMenu = $(this).next(".dropdown-menu");
      $subMenu.toggleClass("show");

      $(this).parent("li").toggleClass("show");

      $(this)
        .parents("li.nav-item.dropdown.show")
        .on("hidden.bs.dropdown", function (e) {
          $(".dropdown-menu .show").removeClass("show");
        });

      if (!$parent.parent().hasClass("navbar-nav")) {
        $el
          .next()
          .css({ top: $el[0].offsetTop, left: $parent.outerWidth() - 4 });
      }

      return false;
    });
  });

})();